import React, { useEffect, useState } from 'react'
import './parent.scss'
import { Tooltip, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { parse } from 'query-string';
import moment from 'moment';
import { getInstituteDeepdiveListAction } from './logic';
import Loader from '../../../common/components/Loader';
import InputWithCopy from '../CopyToClipboard';

export default function InstitutionDetails() {
  const instituteDeepdiveList = useSelector((store) => store.instituteDeepdiveList);

  const { id } = parse(location.search)
  const [instituteDeepDiveData, setInstituteDeepDiveData] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getInstituteDeepdiveListAction({ id }))
  }, []);

  useEffect(() => {
    if (instituteDeepdiveList.flag) {
      setInstituteDeepDiveData(instituteDeepdiveList.data.data)
    }
  }, [JSON.stringify(instituteDeepdiveList)])

  const columns = [
    {
      title: 'Type of Contracts',
      dataIndex: 'type',
      key: 'name',
    },
    {
      title: 'Effective Date',
      dataIndex: 'effective_date_ts',
      render: (d) => {
        if (d) {
          return <div>{moment(d * 1000).format('DD-MM-YYYY')}</div>
        }
        return <div> - </div>
      },
    },
    {
      title: 'Expiry Date',
      dataIndex: 'expiry_date_ts',
      render: (d) => {
        if (d) {
          return <div>{moment(d * 1000).format('DD-MM-YYYY')}</div>
        }
        return <div> - </div>
      },
    },
    {
      title: 'Auto Renewal',
      dataIndex: 'is_auto_renewal',
      render: (d) => {
        if (d) {
          return 'Yes'
        }
        return 'No'
      },
    },
    {
      title: 'Renewal Limit Date',
      dataIndex: 'renewal_limit_date',
      render: (d) => {
        if (d) {
          return <div>{moment(d * 1000).format('DD-MM-YYYY')}</div>
        }
        return <div> - </div>
      },
    },
  ];

  const RenderCardDetails = () => {
    return (
      <div className="content deepdive-content">
        <div className="left-container inst">
          <div className="inst-left-side">
            <div className="content-wrap">
              <div className="key-wrap"> Parent Institution Type: </div>
              <div className="value-wrap">{instituteDeepDiveData?.center_type ? instituteDeepDiveData?.center_type : '-'}</div>
            </div>
            <div className="content-wrap">
              <div className="key-wrap">LandSCAPE ID:</div>
              <div className="value-wrap">{instituteDeepDiveData?.landscape_id ? instituteDeepDiveData?.landscape_id : '-'}</div>
            </div>
            <div className="content-wrap">
              <div className="key-wrap">SIMS ID:</div>
              <div className="value-wrap">{instituteDeepDiveData?.center_sims_ids?.length === 1 ? instituteDeepDiveData?.center_sims_ids : instituteDeepDiveData?.center_sims_ids?.length > 1 ? instituteDeepDiveData?.center_sims_ids?.join(',') : '-'}</div>
            </div>
            <div className="content-wrap">
              <div className="key-wrap">Citeline ID:</div>
              <div className="value-wrap">{instituteDeepDiveData?.center_citline_ids?.length === 1 ? instituteDeepDiveData?.center_citline_ids : instituteDeepDiveData?.center_citline_ids?.length > 1 ? instituteDeepDiveData?.center_citline_ids?.join(',') : '-'}</div>
            </div>
            <div className="content-wrap">
              <div className="key-wrap">OMS org IDs:</div>
              <div className="value-wrap">{instituteDeepDiveData?.oms_org_id ? instituteDeepDiveData?.oms_org_id : '-'}</div>
            </div>
            <div className="content-wrap">
              <div className="key-wrap">OMS loc IDs:</div>
              <div className="value-wrap">{instituteDeepDiveData?.oms_loc_id ? instituteDeepDiveData?.oms_loc_id : '-'}</div>
            </div>
            <div className="content-wrap">
              <div className="key-wrap"> # of Child Centers:</div>
              <div className="value-wrap">{instituteDeepDiveData?.total_child_centers ? instituteDeepDiveData?.total_child_centers : '-'}</div>
            </div>

          </div>

          <div className="inst-center-side">
            <div className="content-wrap">
              <div className="key-wrap">Sources:</div>
              <div className="value-wrap">{instituteDeepDiveData?.sources?.length ? instituteDeepDiveData?.sources.join(', ') : '-'}</div>
            </div>
            <div className="content-wrap">
              <div className="key-wrap">Alliance Contact:</div>
              <div className="value-wrap">{instituteDeepDiveData?.alliance_contact?.length ? instituteDeepDiveData?.alliance_contact.join(', ') : '-'}</div>
            </div>
            <div className="content-wrap">
              <div className="key-wrap">Parexel Contact:</div>
              <div className="value-wrap">{instituteDeepDiveData?.parexel_contact?.length
                ? instituteDeepDiveData?.parexel_contact.join(', ') : '-'}
              </div>
            </div>
            <div className="content-wrap">
              <div className="key-wrap">Headquater Address:</div>
              <div className="value-wrap">
                {instituteDeepDiveData?.address ? (
                  <Tooltip
                    placement="bottom"
                    title={(
                      <div className="content-child">
                        {instituteDeepDiveData.address?.address_line1 && `${instituteDeepDiveData.address?.address_line1}, `}
                        {instituteDeepDiveData.address?.address_line2 && `${instituteDeepDiveData.address?.address_line2}, `}
                        {instituteDeepDiveData.address?.city && `${instituteDeepDiveData.address?.city}, `}
                        {instituteDeepDiveData.address?.state && `${instituteDeepDiveData.address?.state}, `}
                        {instituteDeepDiveData.address?.country && `${instituteDeepDiveData.address?.country}, `}
                        {instituteDeepDiveData.address?.zip}
                      </div>
                    )}
                  >
                    <div className="content-child">
                      {instituteDeepDiveData.address?.address_line1 && `${instituteDeepDiveData.address?.address_line1}, `}
                      {instituteDeepDiveData.address?.address_line2 && `${instituteDeepDiveData.address?.address_line2}, `}
                      {instituteDeepDiveData.address?.city && `${instituteDeepDiveData.address?.city}, `}
                      {instituteDeepDiveData.address?.state && `${instituteDeepDiveData.address?.state}, `}
                      {instituteDeepDiveData.address?.country && `${instituteDeepDiveData.address?.country}, `}
                      {instituteDeepDiveData.address?.zip}
                    </div>
                  </Tooltip>

                ) : (
                  <div className="content-child no-data">No data available</div>
                )}
              </div>
            </div>
          </div>
          <div className="inst-end-side">
            <div className="content-wrap">
              <div className="key-wrap">Email:</div>
              <div className="value-wrap">
                {instituteDeepDiveData.email && instituteDeepDiveData.email.length ? (
                  <Tooltip placement="bottom" title={instituteDeepDiveData.email.join(', ')}>
                    <div className="content-child">
                      <div className="content-child-title">{instituteDeepDiveData.email.join(', ')}</div>
                      <InputWithCopy text={instituteDeepDiveData} type="email" />
                    </div>
                  </Tooltip>
                ) : (
                  <div className="content-child no-data">No data available</div>
                )}
              </div>
            </div>
            <div className="content-wrap">
              <div className="key-wrap">Phone:</div>
              {instituteDeepDiveData.phone_number && instituteDeepDiveData.phone_number.length ? (
                <Tooltip placement="bottom" title={instituteDeepDiveData.phone_number.join(', ')}>
                  <div className="value-wrap ellipsed-cont-insti">{instituteDeepDiveData.phone_number.join(', ')}</div>
                </Tooltip>
              ) : (
                <div className="content-child no-data">No data available</div>
              )}
            </div>
            <div className="content-wrap">
              <div className="key-wrap">Mobile No:</div>
              {instituteDeepDiveData.mobile_number && instituteDeepDiveData.mobile_number.length ? (
                <Tooltip placement="bottom" title={instituteDeepDiveData.mobile_number.join(', ')}>
                  <div className="value-wrap ellipsed-cont-insti">{instituteDeepDiveData.mobile_number.join(', ')}</div>
                </Tooltip>
              ) : (
                <div className="content-child no-data">No data available</div>
              )}
            </div>
            <div className="content-wrap">
              <div className="key-wrap">Fax number:</div>
              {instituteDeepDiveData.fax && instituteDeepDiveData.fax.length ? (
                <Tooltip placement="bottom" title={instituteDeepDiveData.fax.join(', ')}>
                  <div className="value-wrap ellipsed-cont-insti">{instituteDeepDiveData.fax.join(', ')}</div>
                </Tooltip>
              ) : (
                <div className="content-child no-data">No data available</div>
              )}
            </div>
            <div className="content-wrap">
              <div className="key-wrap">URL:</div>
              {instituteDeepDiveData.url ? (
                <Tooltip placement="bottom" title={instituteDeepDiveData.url}>
                  <div className="value-wrap ellipsed-cont-insti">{instituteDeepDiveData.url}</div>
                </Tooltip>
              ) : (
                <div className="content-child no-data">No data available</div>
              )}
            </div>
            {/* <div className="content-wrap">
            <div className="key-wrap">Phone:</div>
            {instituteDeepDiveData.phone_number && instituteDeepDiveData.phone_number.length ? (
              <Tooltip placement="bottom" title={instituteDeepDiveData.phone_number.join(', ')}>
                <div className="value-wrap ellipsed-cont-insti">{instituteDeepDiveData.phone_number.join(', ')}</div>
              </Tooltip>
            ) : (
              <div className="content-child no-data">No data available</div>
            )}
          </div> */}

            {/* <div className="content-wrap">
              <div className="key-wrap">Fax:</div>
              <div className="value-wrap">No data available</div>
            </div>
            <div className="content-wrap">
              <div className="key-wrap">SA Program Entry Date:</div>
              <div className="value-wrap">-</div>
            </div>
            <div className="content-wrap">
              <div className="key-wrap">SA Program End Date:</div>
              <div className="value-wrap">-</div>
            </div> */}
          </div>
        </div>
      </div>
    )
  }

  const renderQuestionAnswerDetails = () => {
    return (
      <div className="tab-ques-wrap-content-card institution-dashboard-ques-wrap-card">
        <div className="institution-dashboard-ques-wrap-card-container">
          {instituteDeepDiveData?.profile_responses?.filter((a) => a.parent_category === 'Institution Dashboard').map((itm) => {
            return (
              <div className="institution-dashboard-ques-wrap-card-container-content">
                <div className="key-wrap-title">{itm.question_text} :</div>
                <div className="value-wrap-desc">{itm.response}</div>
              </div>
            )
          })}

        </div>
      </div>
    )
  }

  return (
    <div className="CardList institution-dashboard">
      <Loader loading={instituteDeepdiveList.loading} error={instituteDeepdiveList.error}>
        <div className="search-result-list">
          <div className="patientcard">
            {RenderCardDetails()}
          </div>
          <div className="tab-ques-wrap-content institution-dashboard-ques-wrap">
            {renderQuestionAnswerDetails()}
          </div>
          <div className="graph-section-results">
            <div className="graph-section-results-title">Type of Contracts:</div>
            <div className="graph-section-results-table">
              <Table
                columns={columns}
                dataSource={instituteDeepDiveData?.contracts?.filter((i) => i.type)}
                pagination={false}
              />
            </div>
          </div>

          <div className="card center-card-ids" style={{ margin: '20px' }}>
            <div className="content deepdive-content">
              <div className="right-side">
                <div className="tab-title"> IDs </div>
                <div className="id-card">
                  <div className="id-title">
                    CCN Number :
                  </div>
                  <div className="id-data">
                    {instituteDeepDiveData?.ccn_number ? instituteDeepDiveData?.ccn_number : '-'}
                  </div>
                </div>
                <div className="id-card">
                  <div className="id-title">
                    Taxation IDs:
                  </div>
                  <div className="id-data">
                    {instituteDeepDiveData?.taxation_ids?.length ? instituteDeepDiveData?.taxation_ids.join(',') : '-'}
                  </div>
                </div>
                <div className="id-card">
                  <div className="id-title">
                    NPI IDs :
                  </div>
                  <div className="id-data">
                    {instituteDeepDiveData?.npi?.length ? instituteDeepDiveData?.npi.join(',') : '-'}
                  </div>
                </div>
                <div className="id-card">
                  <div className="id-title">
                    PAC IDs :
                  </div>
                  <div className="id-data">
                    {instituteDeepDiveData?.pac_ids?.length ? instituteDeepDiveData?.pac_ids.join(',') : '-'}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Loader>

    </div>
  )
}
